import missionController from "./mission_controller";
import { BaseComponent } from "../../../common/base-component.js";

import toggleIcon from "../../../common/images/carat_toggle.svg"

import { DT_M_BREAK } from "../../../common/helpers.js";

const style = `
    .mission-toggle-btn {
        height: 1.5rem;
        width: 1.5rem;
        transition: transform 1s;
        transform: rotate(180deg);
        top: 0.25rem;
        position: relative;
    }
    @media (min-width: ${DT_M_BREAK}) {
        .mission-toggle-btn {
            top: auto;
            align-self: center;
        }
    }

    .rotate {
        transform: rotate(0deg)
    }
`

customElements.define(
    "mission-toggle",
    class extends BaseComponent {
        static observedAttributes = ['data-toggle'];

        #btn;

        constructor() {
            super(style);
            
            missionController.subscribe('toggle-click', this.toggle.bind(this));
            
        }

        attributeChangedCallback(name, oldValue, newValue) {
            super.attributeChangedCallback();            

            if(name === 'data-toggle'){
                newValue == "true" ? this.#btn.classList.add('rotate') : this.#btn.classList.remove('rotate');
            }
        }

        connectedCallback() {
            super.connectedCallback();
            
            this.#btn = this.shadowRoot.getElementById("mission-toggle-btn");
            
            this.#btn.addEventListener("click", () => { 
                missionController.publish('toggle-click', this.dataset.alias) 
            });
        }

        close() {
            if(this.dataset.toggle) {
                delete this.dataset.toggle;
            }
        }

        toggle(alias) {
            if(this.dataset.alias === alias) {
                if(this.dataset.toggle) {
                    this.close();
                } else {
                    this.dataset.toggle = true;
                }
            } else {
                this.close();
            }
        }

        render() {
            return `
                <div class="mission-toggle-btn" id="mission-toggle-btn">
                    <img src="${toggleIcon}"/>
                </div>
            `
        }
    },
);